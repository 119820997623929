import ApiError, { IApiError } from "./ApiError";

export interface IContactRequestModel {
  requesterName: string;
  requesterEmail: string;
  region: string;
  reason: string;
  comments: string | null;
}

export default class ContactRequestDataProvider {

  public readonly baseUrl : string;

  public constructor(baseUrl: string) {
    this.baseUrl = baseUrl;
  }

  public async postContactRequest(contactRequestModel: IContactRequestModel) : Promise<IContactRequestModel> {
    const response : Response = await fetch(
      `${this.baseUrl}/contactRequests`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
        },
        body: JSON.stringify(contactRequestModel)
      });
    
    if (response.ok)
      return (await response.json()) as IContactRequestModel;
    
    if (response.headers.get('Content-Type')?.toLowerCase().startsWith('application/json')) {
      const responseBody : any = await response.json();
      if ('type' in responseBody)
        throw new ApiError(responseBody as IApiError);
    }

    // Otherwise ...
    const text : string = await response.text();
    throw new Error(`The server responded with status code ${response.status} in a manner which was unexpected: ${text}`);
  }
}

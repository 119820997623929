export interface IApiError {
  status: number;
  type: string;
  title: string;
}

export default class ApiError extends Error implements IApiError {

  public static is(error: unknown) : error is ApiError {
    return (
      typeof error === 'object'
      && error !== null
      && error.constructor === ApiError
    );
  }

  public readonly status: number;
  public readonly type: string;
  public readonly title: string;

  constructor(apiError : IApiError) {
    super(`Server error [${apiError.status}]: (${apiError.type}) ${apiError.title}`);

    this.status = apiError.status;
    this.type = apiError.type;
    this.title = apiError.title;
  }

  public get typeSuffix() : string {
    const octothorpeIndex : number = this.type.lastIndexOf('#');
    return this.type.substring(octothorpeIndex + 1);
  }
}

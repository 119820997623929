import { ChangeEvent, FormEvent, useCallback, useMemo, useState } from "react";
import { Button, Col, Container, Form, Row, Spinner } from "react-bootstrap";
import ContactRequestDataProvider, { IContactRequestModel } from "../dataProviders/ContactRequestDataProvider";
import ApiError from "../dataProviders/ApiError";

type ErrorType =
  | 'contact-requests.post.missing-requester-name'
  | 'contact-requests.post.missing-requester-email'
  | 'contact-requests.post.invalid-requester-email'
  | 'contact-requests.post.missing-reason'
  | 'contact-requests.post.missing-region'
  | 'contact-requests.post.not-accepting-requests'
  | '__unknown__'
  ;

const UNKNOWN_ERROR_TYPE : ErrorType = '__unknown__';
const KNOWN_ERROR_TYPES : ErrorType[] = [
  'contact-requests.post.missing-requester-name',
  'contact-requests.post.missing-requester-email',
  'contact-requests.post.invalid-requester-email',
  'contact-requests.post.missing-reason',
  'contact-requests.post.missing-region',
  'contact-requests.post.not-accepting-requests',
];

const REGIONS : string[] = [
  'United States',
  'Canada',
  'Latin America',
  'South America',
  'Europe',
  'Africa',
  'Asia',
  'Australia',
  'Other'
]

interface IPreDefinedReason {
  englishText: string;
  nativeText: string;
}

const OTHER_REASON_ID : string = '__other__';
const PRE_DEFINED_REASONS : IPreDefinedReason[] = [
  {
    englishText: 'I would like to register as a Candidate in the program',
    nativeText: 'I would like to register as a Candidate in the program',
  },
  {
    englishText: 'I would like to use the materials to mentor others',
    nativeText: 'I would like to use the materials to mentor others',
  }
];

// const CONTACT_REQUEST_API_BASE_ADDRESS : string = 'https://localhost:5001/api2'; // Used for testing
const CONTACT_REQUEST_API_BASE_ADDRESS : string = 'https://app.mcnetcli.org/api2';

export default function ContactUs() {

  const [validated, setValidated] = useState<boolean>(false);
  const [sending, setSending] = useState<boolean>(false);
  const [submitted, setSubmitted] = useState<boolean>(false);

  const [requesterName, setRequesterName] = useState<string>('');
  const [requesterEmail, setRequesterEmail] = useState<string>('');
  const [region, setRegion] = useState<string>('');
  const [preDefinedReason, setPreDefinedReason] = useState<string>(PRE_DEFINED_REASONS[0].englishText);
  const [customReason, setCustomReason] = useState<string>('');
  const [comments, setComments] = useState<string>('');

  const [errorType, setErrorType] = useState<ErrorType | undefined>();
  const genericErrorText = useMemo<string | undefined>(() => {
    if (!errorType)
      return undefined;
    
    if (errorType === 'contact-requests.post.not-accepting-requests')
      return "Sorry, we're not accepting contact requests at this moment.";
    else if (!KNOWN_ERROR_TYPES.includes(errorType))
      return 'An unexpected error has occured. Please try again.';
    else
      return undefined;
  }, [errorType]);

  const contactRequestDataProvider = useMemo(() => new ContactRequestDataProvider(CONTACT_REQUEST_API_BASE_ADDRESS), []);

  const reason : string = useMemo(
    () => preDefinedReason === OTHER_REASON_ID ? customReason : preDefinedReason,
    [preDefinedReason, customReason]
  );

  const onRequesterNameChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => setRequesterName(event.target.value),
    [setRequesterName]
  );

  const onRequesterEmailChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => setRequesterEmail(event.target.value),
    [setRequesterEmail]
  );

  const onRegionChange = useCallback(
    (event: ChangeEvent<HTMLSelectElement>) => setRegion(event.target.value),
    [setRegion]
  );

  const onPreDefinedReasonChange = useCallback(
    (event: ChangeEvent<HTMLSelectElement>) => setPreDefinedReason(event.target.value),
    [setPreDefinedReason]
  );

  const onCustomReasonChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => setCustomReason(event.target.value),
    [setCustomReason]
  );

  const onCommentsChange = useCallback(
    (event: ChangeEvent<HTMLTextAreaElement>) => setComments(event.target.value),
    [setComments]
  );

  const onSubmit = useCallback((event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    if (sending)
      return;

    if (event.currentTarget.checkValidity() === false) {
      event.stopPropagation();
      setValidated(true);
    }
    else {
      const payload : IContactRequestModel = {
        requesterName,
        requesterEmail,
        region,
        reason,
        comments,
      };
      
      setSending(true);
      contactRequestDataProvider.postContactRequest(payload)
        .then(() => setSubmitted(true))
        .catch((error: Error) => {
          console.log(`Something went wrong: ${error.message}`);
          if (ApiError.is(error)) {
            setErrorType(error.typeSuffix as ErrorType);
          }
          else {
            setErrorType(UNKNOWN_ERROR_TYPE);
          }
        })
        .finally(() => setSending(false))
    }
  }, [setValidated, requesterName, requesterEmail, region, reason, comments, contactRequestDataProvider, sending]);

  const _PreDefinedReasonControl = useCallback(() => (
    <Form.Select 
      value={preDefinedReason}
      disabled={sending}
      onChange={onPreDefinedReasonChange}
      isInvalid={errorType === 'contact-requests.post.missing-reason'}
    >
      {PRE_DEFINED_REASONS.map((preDefinedReason: IPreDefinedReason) => (
        <option value={preDefinedReason.englishText}>
          {preDefinedReason.nativeText}
        </option>
      ))}
      <option value={OTHER_REASON_ID}>
        Other
      </option>
    </Form.Select>
  ), [preDefinedReason, onPreDefinedReasonChange, errorType, sending]);

  return (
    <section>
      <Container>
        <h2>Contact Us</h2>
        {submitted ? (
          <>
            <h3>Your contact request has been received</h3>
            <p>
              Thank you for submitting a contact request. Your request will be reviewed in a timely manner.
            </p>
          </>
        ) : (
          <Form noValidate validated={validated} onSubmit={onSubmit}>
            <Form.Group className="mb-3">
              <Form.Label>Your name</Form.Label>
              <Form.Control 
                disabled={sending}
                required
                type="text" 
                placeholder="Enter your name"
                value={requesterName}
                onChange={onRequesterNameChange}
                isInvalid={errorType === 'contact-requests.post.missing-requester-name'}
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Your E-mail address</Form.Label>
              <Form.Control
                required
                disabled={sending}
                type="email"
                placeholder="Enter your E-mail address."
                isInvalid={errorType === 'contact-requests.post.missing-requester-email' || errorType === 'contact-requests.post.invalid-requester-email'}
                value={requesterEmail}
                onChange={onRequesterEmailChange}
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Your region of the world</Form.Label>
              <Form.Select
                required
                disabled={sending}
                isInvalid={errorType === 'contact-requests.post.missing-region'}
                value={region}
                onChange={onRegionChange}
              >
                <option value="">
                  Select a region
                </option>
                {REGIONS.map(region => (
                  <option value={region}>
                    {region}
                  </option>
                ))}
              </Form.Select>
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Reason for contacting us</Form.Label>
              {preDefinedReason === OTHER_REASON_ID ? (
                <Row>
                  <Col md={3} className="mb-3 mb-md-0">
                    <_PreDefinedReasonControl/>
                  </Col>
                  <Col md={9}>
                    <Form.Control 
                      type="text"
                      required
                      disabled={sending}
                      placeholder="Enter your reason"
                      isInvalid={errorType === 'contact-requests.post.missing-reason'}
                      value={customReason}
                      onChange={onCustomReasonChange}
                    />
                  </Col>
                </Row>
              ) : (
                <_PreDefinedReasonControl/>
              )}
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Additional comments</Form.Label>
              <Form.Control
                as="textarea"
                disabled={sending}
                type="text"
                placeholder="Enter any additional comments here"
                value={comments}
                onChange={onCommentsChange}
              />
            </Form.Group>
            <div 
              className="text-danger mb-3" 
              style={{ minHeight: 30 }}
            >
              {genericErrorText}
            </div>
            <Button 
              type="submit"
              className="text-right"
              style={{
                minWidth: 300
              }}
            >
              {sending ? (
                <Spinner animation="border" />
              ) : (
                <>Submit Contact Request</>
              )}
            </Button>
          </Form>
        )}
      </Container>
    </section>
  );
}